import {add, endOfMonth, endOfYear, format, parseISO, startOfYear, sub, subWeeks} from "date-fns";
import {cs} from "date-fns/esm/locale";
import {capitalizeFirstLetter, toRomanSimple} from "./string";
import {DataShape} from "./metric";
import {MetricsType} from "../openapi";

export enum Range {
  Week = 'week',
  Month = 'month',
  Months3 = 'months3',
  Months6 = 'months6',
  Year = 'year',
  Custom = 'Custom',
}

export const rangeToElasticPeriod = (range: Range): string => {
  switch (range) {
    case Range.Week:
      return '1w';
    case Range.Month:
      return '1M';
    case Range.Months3:
      return '1M';
    case Range.Months6:
      return '1M';
    case Range.Year:
      return '1y';
    case Range.Custom:
      return '1M';
  }
}

export const dateStringToKey = (dateString: string, range: Range) => {
  const date = parseISO(dateString);

  if (range === Range.Week) {
    return format(date, 'II', {locale: cs})
  } else if (range === Range.Months3) {
    return toRomanSimple(Math.ceil((date.getMonth() + 1) / 3));
  } else if (range === Range.Months6) {
    return toRomanSimple(Math.ceil((date.getMonth() + 1) / 6));
  } else if (range === Range.Year) {
    return '';
  } else {
    return capitalizeFirstLetter(format(date, 'LLLL', {locale: cs}))
  }
}

export function formatDateForBackend(date: Date) {
  return format(date, 'yyyy-MM-dd');
}

export function getScaleValues(range: Range, data: DataShape[]) {
  switch (range) {
    case Range.Week:
      const items = [...data.map(item => item[0])].filter((v, i, a) => a.indexOf(v) === i).sort(); // unique and sort
      let split = null;
      for (let i = 1; i < items.length; ++i) {
        const curr = parseFloat(items[i]);
        const prev = parseFloat(items[i - 1]);
        if (prev + 1 !== curr) {
          split = i;
        }
      }
      if (split) {
        return [...items.slice(split), ...items.slice(0, split)]
      }

      return items;
    case Range.Months3:
      return ['I', 'II', 'III', 'IV'];
    case Range.Months6:
      return ['I', 'II'];
    case Range.Year:
      return ['']
    default:
      return ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'];
  }
}

export function trendFrom(from: Date, to: Date, range: Range) {
  switch (range) {
    case Range.Week:
      return subWeeks(from, 11);
    default:
      return startOfYear(to);
  }
}

export function trendTo(from: Date, to: Date, range: Range) {
  return to;
}

export function trendLastYearFrom(from: Date, to: Date, range: Range) {
  return sub(trendFrom(from, to, range), {years: 1});
}

export function trendLastYearTo(from: Date, to: Date, range: Range) {
  switch (range) {
    case Range.Week:
      return sub(to, {years: 1});
    default:
      return endOfYear(sub(to, {years: 1}));
  }
}

export function metricFilterForSpecialDates(date: Date, endData: Date, range: Range.Months3 | Range.Months6, tags: number[]): MetricsType[] {
  const dates = [];
  while (date < endData) {
    dates.push(date);
    date = add(date, {months: range === Range.Months3 ? 3 : 6});
  }
  const filters = [];
  for (date of dates) {
    filters.push({
      intervalStart: formatDateForBackend(date),
      intervalEnd: formatDateForBackend(endOfMonth(add(date, {months: range === Range.Months3 ? 2 : 5}))),
      period: rangeToElasticPeriod(range),
      tags: tags.join(',')
    })
  }

  return filters
}
